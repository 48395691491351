.outer__container {
    display: flex;
    justify-content: center;
}

.experience__container {
    display: flex;
    width: 60%;
    gap:2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.experience__box {
    display: grid;
    /* background-color: var(--color-bg-variant); */
    width: 7rem;
    height: 7rem;
    border-radius: 4rem;
    justify-content: center;
    align-items: center;
    text-align: center;
}



.experience__img {
    width: auto;
    height: 50px;
    margin: auto;
    margin-bottom: 0.5rem;
    
}



/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 910px) {
    .experience__container {
        width: 80%;
    } 

    .experience__content {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }
}
@media screen and (max-width: 600px) {
    .experience__container {
        width: 90%;
    } 
}