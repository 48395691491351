.container.testimonials__container {
    width: 60%;
    padding-bottom: 3rem;
}

h3 {
    color: var(--color-primary);
    margin-bottom: 0.2rem;
}

.testimonial > h2 {
    font-size: 28px;
    margin-bottom: auto;
}

.client__avatar {
    width: 7rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
    border: 0.4rem solid var(--color-primary-variant);
}

.client__name {
    font-size: 28px;
    font-weight: bold;
    color: white;
}
.client__name:hover {
    font-size: 28px;
    font-weight: bold;
    color: var(--color-primary);
}

.testimonial {
    background: var(--color-primary-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--color-primary);
}

.swiper-button-next,
.swiper-button-prev {
    right:10px;
    padding: 30px;
    color: var(--color-primary) !important;
    fill: black !important;
    stroke: black !important;
}

/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 600px) {
    .container.testimonials__container {
        width: var(--container-width-sm);
    }

    .client__review {
        width: var(--container-width-sm);
    }
}