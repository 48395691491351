.portfolio__container {
    display: flex;
    gap: 2rem;
    justify-content: center;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 1rem;
    border: 1px solid transparent;
    transition: var(--transition);
    height: auto;
    width: calc((100% - 80px) / 3);
    margin: 0 auto; /* Center the item horizontally within the container */
    box-sizing: border-box;
    justify-content: center; /* Optionally, you can center the content inside the item vertically */
  }

.portfolio__item-image {
    border: 1.5rem;
    overflow: hidden;
    height: auto;
    justify-content: center;
}

.portfolio__item-images {
    height: 10rem;
    width: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.portfolio__item h3 {
    margin: 1.2rem 0 1.5rem;
    text-align: center;
    margin-bottom: 0;
    font-size: 30px;
}
.portfolio__item h5 {
    text-align: center;
    margin-bottom: 1.2rem;
}

.portfolio__item-cta {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.icon__box {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap:1rem;
}

.icon {
    height: 50px;
    width: auto;
}

/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1300px) {
    .portfolio__container{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
    .portfolio__item {
        width: 85%;
    }
}

/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 850px) {
    .portfolio__container{
        grid-template-columns: 1fr;
        gap: 1rem;
    }
}