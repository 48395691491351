.work__icons {
    height: 50px;
    width: auto;
}

.work__title {
    font-size: 25px;
    color:black;
    font-weight: bold;
}

.work__company {
    font-size: 20px;
    font-weight: bold;
    color: #2c2c6c
}
.work__company:hover {
    font-size: 20px;
    font-weight: bold;
}