@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

* { 
    /* * means every element on the web page */
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* can be used anywhere in the project */
    --color-bg: #1f1f38;
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77,181,255,0.4);
    --color-white: #fff;
    --color-light: rgba(255,255,255,0.6);

    --transition: all 400 ms;

    /* width of container class, large, medium, small */
    --container-width-lg: 75%; 
    --container-width-md:86%;
    --container-width-sm:90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    display: none;
}

body {
    font-family: 'Poppins', sans-serif;
    color: var(--color-white);
    line-height: 1.7;
    background: linear-gradient(to bottom, rgba(31,31,56,1), rgba(31,31,56,1)), url(./assets/bg-texture.png);
    /* background-blend-mode: overlay; */
}

/* ======= General Styles ======= */

.container {
    width: var(--container-width-lg);
    /* position in middle horizontally */
    margin: 0 auto 0;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h2 {
    font-size: 40px;
    margin-bottom: 1rem;
}

h1 {
    font-size: 2.5rem;
    line-height: 4.3rem;
}

section {
    margin-top: 6rem;
    height: auto;
}

/* Every section will have a h2 and a h5 */
section > h2, section > h5 {
    text-align: center;
    color:var(--color-light);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

/* On hover */
a:hover {
    color:var(--color-white);
}

.btn {
    width: max-content;
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    border-radius: 0.5rem;
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-primary {
    width: max-content;
    display: inline-block;
    background: var(--color-primary);
    color: var(--color-bg);
    padding: 0.75rem 1.2rem;
    cursor: pointer;
    border: 1px solid var(--color-primary);
    transition: var(--transition);
    border-radius: 0.5rem;
}

img {
    display:block;
    width: 100%;
    object-fit: cover;
}

/* ============ MEDIA QUERIES (MEDIUM DEVICES) ============ */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
    section {
        margin-top: 3rem;
    }
}

/* ============ MEDIA QUERIES (SMALL DEVICES) ============ */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }

    section > h2 {
        margin-bottom: 2rem;
    }
    section {
        margin-top: 1rem;
    }
}
